import React, { useContext, useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { useParams } from "react-router-dom";
import { ElementContext } from "../contexts/ElementContext";
import { UserContext } from "../contexts/UserContext";
import SignatureComponent from "./SignatureComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenNib } from "@fortawesome/free-solid-svg-icons";
/**
 * Your Component
 */
function SignatureBox({
  left,
  top,
  index,
  onClickElement,
  selectedPageNo,
  handleChange,
  role,
  payload,
  fileimage,
}) {
  const [open, setOpen] = useState(false);
  const [elements, setElements] = useContext(ElementContext);
  const [user, setUser] = useContext(UserContext);
  const parameter = useParams();
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPad = useRef({});
  const clear = () => {
    sigPad.clear();
  };
  const trim = () => {
    this.setState({
      trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "image-box",
    item: {
      type: "image",
      left,
      top,
      index,
      position: "old",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const disableInput = () => {
    if (user.role.toLowerCase() === role.toLowerCase()) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      {elements[4].elementDetails[
        elements[4].elementDetails?.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        )
      ]?.elementsDesc[index - 1]?.endUserInput !== "" ? (
        <img
          ref={drag}
          // type="text"
          className={`form-control ${
            parameter.encrypteddata
              ? user.role === role
                ? ""
                : "field-hide"
              : ""
          }`}
          onClick={(e) => {
            if (disableInput() === false) {
              setOpen(!open);
              onClickElement("image", index);
            }
          }}
          src={
            elements[4].elementDetails[
              elements[4].elementDetails?.findIndex(
                (pData) => pData.pageNo === selectedPageNo
              )
            ]?.elementsDesc[index - 1]?.endUserInput || ""
          }
          style={{ ...styles.input, left, top }}
          alt=""
        />
      ) : (
        <div
          className={`form-check box-height clickable ${
            parameter.encrypteddata
              ? user.role === role
                ? ""
                : "field-hide"
              : ""
          }`}
          onClick={(e) => {
            if (disableInput() === false) {
              setOpen(!open);
              onClickElement("image", index);
            }
          }}
          style={{ ...styles.input, left, top }}
        >
          <div ref={drag}>
            <FontAwesomeIcon icon={faPenNib} className="mr-2" />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Signature
            </label>
          </div>
        </div>
      )}
      {open && (
        <div id="myModal" className="modal">
          {/* <!-- Modal content --> */}
          <div className="modal-content">
            <SignatureComponent
              setOpen={setOpen}
              handleChange={handleChange}
              index={index}
            />
          </div>
        </div>
      )}
    </>
  );
}
// handleChange("input", index, e.target.value);
const styles = {
  input: {
    background: "transparent",
    zIndex: 1,
    position: "absolute",
    margin: "-3px",
    border: "1px solid black",
    height: "33px",
    width: "120px",
    padding: "5px",
  },
};

export default SignatureBox;

// <input
//   ref={drag}
//   type="button"
//   className={`form-control ${

//   }`}
//   onClick={(e) => setOpen(!open)}
//   value="Signature"
//   disabled={disableInput()}
//   style={{ ...styles.input, left, top }}
// />
