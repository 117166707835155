import React from "react";
import UserRegistration from "../../components/UserRegistration";
import "./Registration.css";

function Registration() {
  return (
    <div className="auth-container">
      <div className="user-details-container">
        <UserRegistration />
      </div>
    </div>
  );
}

export default React.memo(Registration);
