import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import "./Templates.css";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faBars,
  faTrash,
  faCheck,
  faXmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import "./SetUpUser.css";
import swal from "sweetalert";
import { UserContext } from "../../contexts/UserContext";
import axiosInstance from "../../utils/axiosInstance";
function UserSub({ index, val, setLoading }) {
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const { templateid } = useParams();
  const [signerData, setSignerData] = useState({
    name: "",
    order: "",
    id: "",
  });

  const editSigner = async (signerdata) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}signer-details-update`,
        {
          template_id: templateid,
          userID: sessionStorage?.userId,
          role: {
            name: signerdata.name,
            order: signerdata.order,
            id: signerdata.id,
          },
        }
      );
      if (data.statusCode === 200) {
        setUser((prevData) => ({
          ...prevData,
          roleList: [...data.id],
        }));
        setIsEdit(false);
        setLoading(false);
        setSignerData((prevData) => ({
          ...prevData,
          name: "",
          order: "",
          id: "",
        }));
      }
    } catch (err) {
    }
  };

  const deleteSigner = async (id) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}signer-details-delete`,
        {
          template_id: templateid,
          userID: sessionStorage?.userId,
          id,
        }
      );
      if (data.statusCode === 200) {
        setUser((prevData) => ({
          ...prevData,
          roleList: [...data.id],
        }));
        setLoading(false);
      }
    } catch (err) {
    }
  };

  return (
    <div className="col-sm-4 mb-4" key={index}>
      <div className="card border-left-primary shadow">
        <div className="card-body">
          <h5>order: {val.order - 1}</h5>
          <div className="form-group d-inline">
            {isEdit ? (
              <div className="d-flex">
                <input
                  type="text"
                  value={signerData.name}
                  name="role"
                  className="form-control"
                  placeholder="Role"
                  onChange={(e) =>
                    setSignerData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }))
                  }
                  id="role"
                />
                {isEdit && (
                  <>
                    <a
                      className="text-success ml-2 mr-2 icon-size-user clickable"
                      onClick={() => {
                        if (signerData.name === "") {
                          swal("Opps", "Roles Name is required", "warning");
                        } else {
                          const indexname = user.roleList.findIndex(
                            (val, idx) =>
                              val.role.toLowerCase() ===
                              signerData.name.toLowerCase()
                          );
                          if (indexname !== -1) {
                            swal("Opps", "Roles Names Exist", "warning");
                          } else {
                            editSigner(signerData);
                          }
                        }
                        
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </a>{" "}
                    <a
                      className="text-danger ml-2 mr-2 icon-size-user clickable"
                      onClick={() => {
                        setIsEdit(false);
                        setSignerData((prevData) => ({
                          ...prevData,
                          name: "",
                          order: "",
                          id: "",
                        }));
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </a>
                  </>
                )}
              </div>
            ) : (
              <h3 className="text-primary">
                {val.role}
                <span className="float-right">
                  <a
                    className="text-success icon-size-user mr-2 clickable"
                    onClick={() => {
                      setIsEdit(true);
                      setSignerData((prevData) => ({
                        ...prevData,
                        name: val.role,
                        order: val.order,
                        id: val.id,
                      }));
                    }}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </a>{" "}
                  <a
                    className="text-danger icon-size-user mr-2 clickable"
                    onClick={() => {
                      deleteSigner(val.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </a>
                </span>
              </h3>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(UserSub);
