import React, { useState, useEffect } from "react";
import { useDrag } from "react-dnd";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinimize } from "@fortawesome/free-solid-svg-icons";
/**
 * Your Component
 */
function SquareBoxDraggableInitial() {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "input-box",
    item: {
      type: "input",
      position: "new",
      labelName: "initial",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div className="form-check box-height">
      <div ref={drag}>
        <FontAwesomeIcon icon={faMinimize} className="mr-2" />
        <label className="form-check-label" htmlFor="exampleCheck1">
          Initial
        </label>
      </div>
    </div>
  );
}

export default React.memo(SquareBoxDraggableInitial);
