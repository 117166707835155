// import Header from "../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import "./layout.css"
export default function DefaultLayout(props) {
  return (
    <>
      {/* <a className="float">
        <FontAwesomeIcon icon={faLeftLong} className="mr-2 my-float" />
      </a> */}

      {props.children}
    </>
  );
}
