import React from 'react'
import UserDetails from '../../components/UserDetails'
import './User.css'

function User() {
  return (
    <div className='auth-container'>
      <div className='user-details-container'>
        <UserDetails />
      </div>
    </div>
  )
}

export default React.memo(User)