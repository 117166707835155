import { getWindowSize, SizeContext } from "./contexts/SizeContext";
import Router from "./routes";
import { useContext, useEffect } from "react";

function App() {
  // const [windowSize, setWindowSize] = useState(getWindowSize());
  const [size, setSize] = useContext(SizeContext);

  return <Router />;
}

export default App;
