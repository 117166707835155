import React from "react";
//React 17
// import ReactDOM from "react-dom";
//React 18
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SetFileProvider from "./contexts/SetFileContext";
import UserContextProvider from "./contexts/UserContext";
import ElementContextProvider from "./contexts/ElementContext";
import SizeContextProvider from "./contexts/SizeContext";

//React 18
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SizeContextProvider>
        <UserContextProvider>
          <ElementContextProvider>
            <SetFileProvider>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </SetFileProvider>
          </ElementContextProvider>
        </UserContextProvider>
      </SizeContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

//React 17
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <SizeContextProvider>
//         <UserContextProvider>
//           <ElementContextProvider>
//             <SetFileProvider>
//               <DndProvider backend={HTML5Backend}>
//                 <App />
//               </DndProvider>
//             </SetFileProvider>
//           </ElementContextProvider>
//         </UserContextProvider>
//       </SizeContextProvider>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
