import React, { useContext } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { SetFileContext } from "../../contexts/SetFileContext";
import './ViewPdf.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ViewPdf({ pageNumber }) {
  const [fileInfo, setFileInfo] = useContext(SetFileContext);
  return (
    <>
      <Document
        file={URL.createObjectURL(fileInfo)}
        // file='../../assets/pdfs/dummy.pdf'
        onLoadSuccess={({ numPages }) =>
          console.log("Total number of pages", numPages)
        }
      >
        <Page
          size="A4"
          pageNumber={pageNumber}
          style={{ backgroundColor: "tomato", width: "800px", height: "900px" }}
        />
      </Document>
    </>
  );
}

export default React.memo(ViewPdf);
