import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Congratulations.css";

function Congratulations() {
  const [addclass, setAddclass] = useState(false);
  const navigate = useNavigate();
  // const { history } = useRouter();
  useEffect(() => {
    setTimeout(function () {
      setAddclass(true);
    }, 500);
  }, []);

  //   useEffect(
  //     () =>
  //       navigation.addListener("beforeRemove", (e) => {
  //         if (!hasUnsavedChanges) {
  //           // If we don't have unsaved changes, then we don't need to do anything
  //           return;
  //         }
  //         // Prevent default behavior of leaving the screen
  //         e.preventDefault();
  //       }),
  //     [navigation, hasUnsavedChanges]
  //   );

  return (
    <div className="contain">
      <div className="congrats">
        <h1>
          Congrat<span className="hide">ulation</span>s !
        </h1>
        <div className={`done ${addclass ? "drawn" : ""}`}>
          <svg
            version="1.1"
            id="tick"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 37 37"
            style={{ enableBackground: "new 0 0 37 37", marginTop: "10px" }}
            xmlSpace="preserve"
          >
            <path
              className="circ path"
              style={{
                fill: "#0cdcc7",
                stroke: "#07a796",
                strokeWidth: "3",
                strokeLinejoin: "round",
                strokeMiterlimit: "10",
              }}
              d="
	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
            />
            <polyline
              className="tick path"
              style={{
                fill: "none",
                stroke: "#fff",
                strokeWidth: "3",
                strokeLinejoin: "round",
                strokeMiterlimit: "10",
              }}
              //   style="fill:none;stroke:#fff;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;"
              points="
	11.6,20 15.9,24.2 26.4,13.8 "
            />
          </svg>
        </div>
        <div className="text">
          <p>
            You have successfully filled your data using Cloudsignpro. <br />
            You can visit our by clicking on the button below
            <br />
            <a
              href="https://cloudsignpro.com/"
            //   type="button"
              className="btn btn-primary mt-4"
              onClick={() => {
                navigate("https://cloudsignpro.com/");
              }}
            >
              Ok
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
export default React.memo(Congratulations);
