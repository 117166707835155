// import AuthGuard from "guard/authguard";
import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import AuthGuard from "../guard/authguard";
import DefaultLayout from "../layout";
import { UploadPdf, SetUpPdf, User, Templates } from "../pages";
import Congratulations from "../pages/CongratulationsPage/Congratulations";
import Registration from "../pages/Registration/Registration";
import SetUpUser from "../pages/SetUpUser/SetUpUser";
// import DefaultLayout from "../layout";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense fallback={<div></div>}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/fill-up",
      element: (
        <AuthGuard>
          <SetUpPdf />
        </AuthGuard>
      ),
    },
    {
      path: "/fill-up/:encrypteddata",
      element: (
        <AuthGuard>
          <SetUpPdf />
        </AuthGuard>
      ),
    },
    {
      path: "/fill-up/:encrypteddata/:contractid",
      element: (
        <AuthGuard>
          <SetUpPdf />
        </AuthGuard>
      ),
    },
    {
      path: "/fill-up/:encrypteddata/:contractid/:roleid",
      element: (
        <AuthGuard>
          <SetUpPdf />
        </AuthGuard>
      ),
    },
    {
      path: "/set-up",
      element: (
        <AuthGuard>
          <SetUpPdf />
        </AuthGuard>
      ),
    },
    {
      path: "/set-user/:templateid",
      element: (
        <AuthGuard>
          <DefaultLayout>
            <SetUpUser />
          </DefaultLayout>
        </AuthGuard>
      ),
    },
    {
      path: "/templates",
      element: (
        <AuthGuard>
          <DefaultLayout>
            <Templates />
          </DefaultLayout>
        </AuthGuard>
      ),
    },
    {
      path: "/usersuccess",
      element: (
        <Congratulations/>
      ),
    },
    {
      path: "/templates/:accesstoken",
      element: (
        // <AuthGuard>
        <DefaultLayout>
          <Templates />
        </DefaultLayout>
        // </AuthGuard>
      ),
    },
    {
      path: "/upload-pdf",
      element: (
        <AuthGuard>
          <UploadPdf />
        </AuthGuard>
      ),
    },
    {
      path: "/registration",
      element: <Registration />,
    },
    {
      path: "/login",
      element: <User />,
    },
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },

    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}
