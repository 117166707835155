// ----------------------------------------------------------------------

import axiosInstance from "./axiosInstance";

// import { dataSetType, userDetType } from "global/types";

const removeSession = () => {
  //   localStorage.removeItem("tokens");
  //   localStorage.removeItem("userData");
  localStorage.removeItem("access_token");
  delete axiosInstance.defaults.headers.common.Authorization;
};

const setSession = (access_token) => {
  if (access_token) {
    // localStorage.setItem("tokens", JSON.stringify(tokens));
    // updateUser(user);
    localStorage.setItem("access_token", access_token);
    setToken(access_token);
  } else {
    removeSession();
  }
};

const updateUser = (user) => {
  //   localStorage.setItem("userData", JSON.stringify(user));
};

const setToken = (access_token) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${access_token}`;
};

export { setSession, removeSession, setToken, updateUser };
