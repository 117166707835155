import React, { useContext } from "react";
import { SetFileContext } from "../contexts/SetFileContext";
import ViewPdf from "./ViewPdf/ViewPdf";

function FileThumbnail({ onFileChoose, UploadImg, fileName, cancelFile }) {
  const [fileInfo, setFileInfo] = useContext(SetFileContext);

  return (
    <div
      style={{
        width: "fit-content",
        margin: "auto",
        display: "flex",
        height: "100%",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="file-upload"
    >
      {!!fileInfo ? (
        <ViewPdf pageNumber={1} />
      ) : (
        <div className="custom-file custom-file-new">
          <input
            type="file"
            accept="application/pdf"
            className="custom-file-input clickable"
            id="customFile"
            onChange={onFileChoose}
            // style={{ display: "none" }}
          />
          <label htmlFor="customFile">
            <img src={UploadImg} width="120px" alt="" className="clickable" />
            <div className="clearfix"></div>
            <small className="mt-3 d-block txt-small-pdf-upload clickable">
              {fileName === "" && "Click to browse pdf"}
            </small>
          </label>
        </div>
      )}
      {!!fileInfo === true && (
        <a
          className="pdf-upload-cancel-btn mt-3 btn btn-secondary custom-btn-cls clickable"
          onClick={cancelFile}
          style={{zIndex:"999"}}
        >
          CANCEL
        </a>
      )}
    </div>
  );
}

export default React.memo(FileThumbnail);
