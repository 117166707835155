import React, { useContext, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { useParams } from "react-router-dom";
import { ElementContext } from "../contexts/ElementContext";
import { UserContext } from "../contexts/UserContext";

/**
 * Your Component
 */
function CheckBox({
  left,
  top,
  index,
  onClickElement,
  selectedPageNo,
  handleChange,
  role
}) {
  const parameter = useParams();
   const [user, setUser] = useContext(UserContext);
  const [elements, setElements] = useContext(ElementContext);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "check-box",
    item: {
      type: "check",
      left,
      top,
      index,
      position: "old",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
 const disableInput = () => {
  if (user.role === role) {
    return false;
  } else {
    return true;
  }
  //  if (user.email !== "") {
     
  //  } else {
  //    if (parameter.guestmail === role) {
  //      return false;
  //    } else {
  //      return true;
  //    }
  //  }
 };
  return (
    <>
      <input
        ref={drag}
        type="checkbox"
        className="form-control"
        onClick={(e) => onClickElement("check", index)}
        onChange={(e) => handleChange("check", index, e.target.value)}
        value={
          elements[0].elementDetails[
            elements[0].elementDetails?.findIndex(
              (pData) => pData.pageNo === selectedPageNo
            )
          ]?.elementsDesc[index - 1]?.endUserInput || ""
        }
        style={{ ...styles.check, left, top }}
      />
    </>
  );
}

const styles = {
  check: {
    background: "transparent",
    zIndex: 1,
    position: "absolute",
    margin: "-3px",
    border: "1px solid black",
    height: "16px",
    width: "60px",
    padding: "5px",
    width: "fit-content",
  },
};

export default CheckBox;
