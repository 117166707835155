import "./SetUpPdf.css";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDrop } from "react-dnd";
import { Document, Page, pdfjs } from "react-pdf";
import AWS from "../../configs/AWS";
import InputBox from "../../components/InputBox";
// import { useMousePosition } from '../../components/MousePosition';
import SidebarLeft from "../../components/SidebarLeft/SidebarLeft";
import { ElementContext } from "../../contexts/ElementContext";
import SidebarRight from "../../components/SidebarRight/SidebarRight";
import ViewPdf from "../../components/ViewPdf/ViewPdf";
import { UserContext } from "../../contexts/UserContext";
import CheckBox from "../../components/CheckBox";
import RadioBox from "../../components/RadioBox";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { SetFileContext } from "../../contexts/SetFileContext";
import axiosInstance from "../../utils/axiosInstance";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import lazyloaddata from "../../utils/lazyloaddata";
import SignatureBox from "../../components/SignatureBox";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// @selectedPageNo used as a global data
// as this drop method from react dnd unable to identify the useState/useContext changes
let selectedPageNo = 1;

const eDtls = [];

const elemList = [
  {
    id: 1,
    type: "input",
    elementDetails: [],
  },
  {
    id: 2,
    type: "check",
    elementDetails: [],
  },
  {
    id: 3,
    type: "radio",
    elementDetails: [],
  },
  {
    id: 4,
    type: "date-input",
    elementDetails: [],
  },
  {
    id: 5,
    type: "image",
    elementDetails: [],
  },
];

/**
 * Your Component
 */
let id = 0;
function SetUpPdf() {
  const { state: pageState } = useLocation();

  const [elements, setElements] = useContext(ElementContext);
  const [user, setUser] = useContext(UserContext);
  const [selectedPage, setSelectedPageNo] = useState(1);
  const [selectedItemInfo, setSelectedItemInfo] = useState(null);
  const myRef = useRef();
  const navigate = useNavigate();
  const [fileInfo, setFileInfo] = useContext(SetFileContext);
  const parameter = useParams();
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  // const { height, width } = useWindowDimensions();

  useEffectOnce(() => {
    if (parameter.encrypteddata !== undefined) {
      fetchdecryptData();
    }
    if (parameter.contractid !== undefined) {
      sessionStorage.setItem("contractid", parameter.contractid);
    }
    const px = myRef.current.offsetLeft;
    const py = myRef.current.offsetTop;
    setPosition((prevState) => ({
      ...prevState,
      x: px,
      y: py,
    }));

    return () => {
      console.log(elemList, "HEllo world");
      setElements([...elemList]);
      setUser((prevData) => ({ ...prevData, roleList: [] }));
    };
  }, []);
  // useEffect(()=>{
  //   setElements([...elemList]);
  //   // return () => {
  //   //   console.log(elemList, "HEllo world");
  //   //   // setElements([...elemList]);
  //   //   // setUser((prevData) => ({ ...prevData, roleList: [] }));
  //   // };
  // },[])

  const fetchdecryptData = async () => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}decrypt-template-url`,
        { encryptedData: parameter.encrypteddata }
      );

      if (data.status) {
        // let tempdata = JSON.parse(data.decryptedData || {});

        let decryptarr = data.decryptedData.split("/");
        setPdfFile(decryptarr[0]);
        loadPdfPayload(decryptarr[2]);
        navigate(`/fill-up/${parameter.encrypteddata}`, {
          state: {
            pageType: "set-up",
            fileName: decryptarr[0],
            numberOfPages: decryptarr[1],
          },
        });
        // sessionStorage.setItem("userId", tempdata.userID);
        // // sessionStorage.userId = data?.userID;
        // setSession(tempdata.access_token);
        setUser((prevData) => ({
          ...prevData,
          role: decryptarr[3],
          template_id: decryptarr[5],
        }));
        // navigate(`/templates`);
      } else {
      }

      // navigate(`/set-up`, { state: { pageType: 'set-up' }})
    } catch (error) {}
  };

  const setPdfFile = (filename) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: filename,
    };
    setUser((prevData) => {
      return { ...prevData, fileName: filename };
    });
    s3.getObject(params, (err, data) => {
      if (err) {
        console.log("bucket err", err);
      } else {
        let pdfBlob = new Blob([new Uint8Array(data.Body)], {
          type: "application/pdf",
        });
        setFileInfo(pdfBlob);
      }
    });
  };

  const [boxPosition, setBoxposition] = useState({
    x: 10,
    y: 10,
    drop: false,
  });
  const accepts = [
    "input-box",
    "check-box",
    "radio-button",
    "image-box",
    "date-box",
  ];

  const [{ isOver }, drop] = useDrop(() => ({
    accept: accepts,
    drop: (item, monitor) => {
      // const { x: startPositionX, y: startPositionY } = monitor.getInitialClientOffset()
      // const { x: fromStartPositionX, y: fromStartPositionY } = monitor.getDifferenceFromInitialOffset()
      const { x: exactPositionX, y: exactPositionY } =
        monitor.getClientOffset();
      if (item.position === "new") {
        onDragNewItem(item, exactPositionX, exactPositionY, id);
        id = id + 1;
      } else if (item.position === "old") {
        onDragOldItem(item, exactPositionX, exactPositionY);
      }

      setBoxposition({
        x: exactPositionX,
        y: exactPositionY,
        drop: true,
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const loadPdfPayload = async (guestId) => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}fetch-payloadData`,
        { guestId }
      );

      let tempdata = JSON.parse(
        data.replace(/'/g, '"').replace(/None/g, "null")
      ).rawpayload;

      let newdata = [...lazyloaddata(tempdata)];
      newdata.forEach((element) => {
        element.children.forEach((elem) => {
          onSetPayloadItem(element.name, elem);
        });
      });
    } catch (error) {}
  };

  const onSetPayloadItem = (type, details) => {
    let elementDetails;
    let pageIndex;
    switch (type) {
      case "input":
        elementDetails = elements[0].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === details.pageNo
        );

        if (pageIndex === -1) {
          // for new-entry
          elementDetails.push({
            pageNo: details.pageNo,
            elementsDesc: [
              {
                type: "input",
                description: details.description,
                element: (
                  <InputBox
                    key={details.element.key}
                    left={details.element.props.left}
                    top={details.element.props.top}
                    index={details.element.props.index}
                    role={details.role}
                    selectedPageNo={details.element.props.selectedPageNo}
                    onClickElement={onClickElement}
                    handleChange={handleChange}
                    deletefield={deletefield}
                    payload={details.label}
                  />
                ),
                positionX: details.positionX,
                positionY: details.positionY,
                endUserInput: details.endUserInput,
                label: details.label,
                role: details.role,
                roleID: details.roleID,
                boxid: details.boxid,
                required: details.required,
              },
            ],
          });
          onSelectItem(
            elementDetails[elementDetails.length - 1].elementsDesc[0],
            0
          );
        } else {
          // for old-entry

          const newIndex = elementDetails[pageIndex].elementsDesc.length + 1;
          elementDetails[pageIndex].elementsDesc.push({
            type: "input",
            description: details.description,
            element: (
              <InputBox
                key={details.element.key}
                left={details.element.props.left}
                top={details.element.props.top}
                index={details.element.props.index}
                role={details.role}
                selectedPageNo={details.element.props.selectedPageNo}
                onClickElement={onClickElement}
                handleChange={handleChange}
                deletefield={deletefield}
                payload={details.label}
              />
            ),
            positionX: details.positionX,
            positionY: details.positionY,
            endUserInput: details.endUserInput,
            label: details.label,
            role: details.role,
            roleID: details.roleID,
            boxid: details.boxid,
            required: details.required,
          });

          onSelectItem(
            elementDetails[pageIndex].elementsDesc[newIndex - 1],
            newIndex - 1
          );
        }
        break;

      case "check":
        elementDetails = elements[1].elementDetails;

        elementDetails.push({
          pageNo: details.pageNo,
          elementsDesc: [
            {
              type: "check",
              description: details.description,
              element: (
                <CheckBox
                  key={details.element.key}
                  left={details.element.props.left}
                  top={details.element.props.top}
                  index={details.element.props.index}
                  role={details.role}
                  onClickElement={onClickElement}
                  selectedPageNo={details.element.props.selectedPageNo}
                  handleChange={handleChange}
                  deletefield={deletefield}
                />
              ),
              positionX: details.positionX,
              positionY: details.positionY,
              endUserInput: details.endUserInput,
              label: details.label,
              role: details.role,
              boxid: details.boxid,
              required: details.required,
            },
          ],
        });
        onSelectItem(
          elementDetails[elementDetails.length - 1].elementsDesc[0],
          0
        );

        break;

      case "radio":
        elementDetails = elements[2].elementDetails;
        elementDetails.push({
          pageNo: details.pageNo,
          elementsDesc: [
            {
              type: "radio",
              description: details.description,
              element: (
                <RadioBox
                  key={details.element.key}
                  left={details.element.props.left}
                  top={details.element.props.top}
                  index={details.element.props.index}
                  role={details.role}
                  onClickElement={onClickElement}
                  selectedPageNo={details.element.props.selectedPageNo}
                  handleChange={handleChange}
                  deletefield={deletefield}
                />
              ),
              positionX: details.positionX,
              positionY: details.positionY,
              endUserInput: details.endUserInput,
              label: details.label,
              role: details.role,
              boxid: details.boxid,
              required: details.required,
            },
          ],
        });
        onSelectItem(
          elementDetails[elementDetails.length - 1].elementsDesc[0],
          0
        );

        break;

      case "date-input":
        elementDetails = elements[3].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === details.pageNo
        );

        if (pageIndex === -1) {
          // for new-entry
          elementDetails.push({
            pageNo: details.pageNo,
            elementsDesc: [
              {
                type: "date-input",
                description: details.description,
                element: (
                  <InputBox
                    key={details.element.key}
                    left={details.element.props.left}
                    top={details.element.props.top}
                    index={details.element.props.index}
                    role={details.role}
                    selectedPageNo={details.element.props.selectedPageNo}
                    onClickElement={onClickElement}
                    handleChange={handleChange}
                    deletefield={deletefield}
                    payload={details.label}
                    isdate="true"
                  />
                ),
                positionX: details.positionX,
                positionY: details.positionY,
                endUserInput: details.endUserInput,
                label: details.label,
                role: details.role,
                roleID: details.roleID,
                boxid: details.boxid,
                required: details.required,
              },
            ],
          });
          onSelectItem(
            elementDetails[elementDetails.length - 1].elementsDesc[0],
            0
          );
        } else {
          // for old-entry

          const newIndex = elementDetails[pageIndex].elementsDesc.length + 1;
          elementDetails[pageIndex].elementsDesc.push({
            type: "date-input",
            description: details.description,
            element: (
              <InputBox
                key={details.element.key}
                left={details.element.props.left}
                top={details.element.props.top}
                index={details.element.props.index}
                role={details.role}
                selectedPageNo={details.element.props.selectedPageNo}
                onClickElement={onClickElement}
                handleChange={handleChange}
                deletefield={deletefield}
                payload={details.label}
                isdate="true"
              />
            ),
            positionX: details.positionX,
            positionY: details.positionY,
            endUserInput: details.endUserInput,
            label: details.label,
            role: details.role,
            roleID: details.roleID,
            boxid: details.boxid,
            required: details.required,
          });

          onSelectItem(
            elementDetails[pageIndex].elementsDesc[newIndex - 1],
            newIndex - 1
          );
        }
        break;
      case "image":
        elementDetails = elements[4].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === details.pageNo
        );

        if (pageIndex === -1) {
          // for new-entry
          elementDetails.push({
            pageNo: details.pageNo,
            elementsDesc: [
              {
                type: "image",
                description: details.description,
                element: (
                  <SignatureBox
                    key={details.element.key}
                    left={details.element.props.left}
                    top={details.element.props.top}
                    index={details.element.props.index}
                    role={details.role}
                    selectedPageNo={details.element.props.selectedPageNo}
                    onClickElement={onClickElement}
                    handleChange={handleChange}
                    deletefield={deletefield}
                    payload={details.labelName}
                  />
                ),
                positionX: details.positionX,
                positionY: details.positionY,
                endUserInput: details.endUserInput,
                label: details.label,
                labelID: details.labelName,
                role: details.role,
                roleID: details.roleID,
                boxid: details.boxid,
                required: details.required,
              },
            ],
          });
          onSelectItem(
            elementDetails[elementDetails.length - 1].elementsDesc[0],
            0
          );
        } else {
          // for old-entry

          const newIndex = elementDetails[pageIndex].elementsDesc.length + 1;
          elementDetails[pageIndex].elementsDesc.push({
            type: "image",
            description: details.description,
            element: (
              <SignatureBox
                key={details.element.key}
                left={details.element.props.left}
                top={details.element.props.top}
                index={details.element.props.index}
                role={details.role}
                selectedPageNo={details.element.props.selectedPageNo}
                onClickElement={onClickElement}
                handleChange={handleChange}
                deletefield={deletefield}
                payload={details.labelName}
              />
            ),
            positionX: details.positionX,
            positionY: details.positionY,
            endUserInput: details.endUserInput,
            label: details.label,
            labelID: details.labelName,
            role: details.role,
            roleID: details.roleID,
            boxid: details.boxid,
            required: details.required,
          });

          onSelectItem(
            elementDetails[pageIndex].elementsDesc[newIndex - 1],
            newIndex - 1
          );
        }

        break;
      default:
        break;
    }
  };
  const deletefield = (index, elementType) => {
    let elementDetails;
    let pageIndex;

    switch (elementType) {
      case "input":
        elementDetails = elements[0].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        console.log(elementDetails, index, pageIndex, "delete");
        elementDetails[pageIndex].elementsDesc.splice(index, 1);
        setElements([...elements]);
        setSelectedItemInfo(null);
        break;
      case "image":
        elementDetails = elements[4].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        elementDetails[pageIndex].elementsDesc.splice(index, 1);
        setElements([...elements]);
        setSelectedItemInfo(null);
        break;
      case "date-input":
        elementDetails = elements[3].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        elementDetails[pageIndex].elementsDesc.splice(index, 1);
        console.log(elementDetails, "hi");
        setElements([...elements]);
        setSelectedItemInfo(null);
        break;
      case "check":
        elementDetails = elements[1].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        elementDetails[pageIndex].elementsDesc.splice(index, 1);
        setElements([...elements]);
        setSelectedItemInfo(null);
        break;

      case "radio":
        elementDetails = elements[2].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        elementDetails[pageIndex].elementsDesc.splice(index, 1);
        setElements([...elements]);
        setSelectedItemInfo(null);
        break;
      default:
        break;
    }
  };
  const onDragNewItem = ({ type, labelName }, x, y, boxID) => {
    let elementDetails;
    let pageIndex;

    switch (type) {
      case "image":
        elementDetails = elements[4].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        if (pageIndex === -1) {
          // for new-entry
          elementDetails.push({
            pageNo: selectedPageNo,
            elementsDesc: [
              {
                type: "image",
                description: "",
                element: (
                  <SignatureBox
                    key={1}
                    left={x}
                    top={y}
                    index={1}
                    onClickElement={onClickElement}
                    selectedPageNo={selectedPageNo}
                    handleChange={handleChange}
                    deletefield={deletefield}
                    role={user.roleList[0].role}
                    payload={labelName}
                  />
                ),
                positionX: x,
                positionY: y,
                endUserInput: "",
                label: `${labelName} ${boxID}`,
                labelID: labelName,
                role: user.roleList[0].role,
                roleID: user.roleList[0].id,
                boxid: boxID,
                required: 1,
              },
            ],
          });
          onSelectItem(
            elementDetails[elementDetails.length - 1].elementsDesc[0],
            0
          );
        } else {
          // for old-entry

          const newIndex = elementDetails[pageIndex].elementsDesc.length + 1;
          elementDetails[pageIndex].elementsDesc.push({
            type: "image",
            description: "",
            element: (
              <SignatureBox
                key={newIndex}
                left={x}
                top={y}
                index={newIndex}
                onClickElement={onClickElement}
                selectedPageNo={selectedPageNo}
                handleChange={handleChange}
                deletefield={deletefield}
                role={user.roleList[0].role}
                payload={labelName}
              />
            ),
            positionX: x,
            positionY: y,
            endUserInput: "",
            label: `${labelName} ${boxID}`,
            labelID: labelName,
            role: user.roleList[0].role,
            roleID: user.roleList[0].id,
            boxid: boxID,
            required: 1,
          });

          onSelectItem(
            elementDetails[pageIndex].elementsDesc[newIndex - 1],
            newIndex - 1
          );
        }

        break;
      case "date-input":
        elementDetails = elements[3].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        if (pageIndex === -1) {
          // for new-entry
          elementDetails.push({
            pageNo: selectedPageNo,
            elementsDesc: [
              {
                type: "date-input",
                description: "",
                element: (
                  <InputBox
                    key={1}
                    left={x}
                    top={y}
                    index={1}
                    onClickElement={onClickElement}
                    selectedPageNo={selectedPageNo}
                    handleChange={handleChange}
                    deletefield={deletefield}
                    role={user.roleList[0].role}
                    payload={labelName}
                    isdate="true"
                  />
                ),
                positionX: x,
                positionY: y,
                endUserInput: "",
                label: `${labelName} ${boxID}`,
                labelID: labelName,
                role: user.roleList[0].role,
                roleID: user.roleList[0].id,
                boxid: boxID,
                required: 1,
              },
            ],
          });
          onSelectItem(
            elementDetails[elementDetails.length - 1].elementsDesc[0],
            0
          );
        } else {
          // for old-entry

          const newIndex = elementDetails[pageIndex].elementsDesc.length + 1;
          elementDetails[pageIndex].elementsDesc.push({
            type: "date-input",
            description: "",
            element: (
              <InputBox
                key={newIndex}
                left={x}
                top={y}
                index={newIndex}
                onClickElement={onClickElement}
                selectedPageNo={selectedPageNo}
                handleChange={handleChange}
                deletefield={deletefield}
                role={user.roleList[0].role}
                payload={labelName}
                isdate="true"
              />
            ),
            positionX: x,
            positionY: y,
            endUserInput: "",
            label: `${labelName} ${boxID}`,
            labelID: labelName,
            role: user.roleList[0].role,
            roleID: user.roleList[0].id,
            boxid: boxID,
            required: 1,
          });

          onSelectItem(
            elementDetails[pageIndex].elementsDesc[newIndex - 1],
            newIndex - 1
          );
        }

        break;
      case "input":
        elementDetails = elements[0].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        if (pageIndex === -1) {
          // for new-entry
          elementDetails.push({
            pageNo: selectedPageNo,
            elementsDesc: [
              {
                type: "input",
                description: "",
                element: (
                  <InputBox
                    key={1}
                    left={x}
                    top={y}
                    index={1}
                    onClickElement={onClickElement}
                    selectedPageNo={selectedPageNo}
                    handleChange={handleChange}
                    deletefield={deletefield}
                    role={user.roleList[0].role}
                    payload={labelName}
                  />
                ),
                positionX: x,
                positionY: y,
                endUserInput: "",
                label: `${labelName} ${boxID}`,
                labelID: labelName,
                role: user.roleList[0].role,
                roleID: user.roleList[0].id,
                boxid: boxID,
                required: 1,
              },
            ],
          });
          onSelectItem(
            elementDetails[elementDetails.length - 1].elementsDesc[0],
            0
          );
        } else {
          // for old-entry

          const newIndex = elementDetails[pageIndex].elementsDesc.length + 1;
          elementDetails[pageIndex].elementsDesc.push({
            type: "input",
            description: "",
            element: (
              <InputBox
                key={newIndex}
                left={x}
                top={y}
                index={newIndex}
                onClickElement={onClickElement}
                selectedPageNo={selectedPageNo}
                handleChange={handleChange}
                deletefield={deletefield}
                role={user.roleList[0].role}
                payload={labelName}
              />
            ),
            positionX: x,
            positionY: y,
            endUserInput: "",
            label: `${labelName} ${boxID}`,
            labelID: labelName,
            role: user.roleList[0].role,
            roleID: user.roleList[0].id,
            boxid: boxID,
            required: 1,
          });

          onSelectItem(
            elementDetails[pageIndex].elementsDesc[newIndex - 1],
            newIndex - 1
          );
        }

        break;

      case "check":
        elementDetails = elements[1].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        if (pageIndex === -1) {
          // for new-entry
          elementDetails.push({
            pageNo: selectedPageNo,
            elementsDesc: [
              {
                type: "check",
                description: "",
                element: (
                  <CheckBox
                    key={1}
                    left={x}
                    top={y}
                    index={1}
                    onClickElement={onClickElement}
                    selectedPageNo={selectedPageNo}
                    handleChange={handleChange}
                    deletefield={deletefield}
                    role={user.roleList[0].role}
                  />
                ),
                positionX: x,
                positionY: y,
                endUserInput: "",
                label: "",
                role: user.roleList[0].role,
                roleID: user.roleList[0].id,
                boxid: boxID,
                required: 1,
              },
            ],
          });

          onSelectItem(
            elementDetails[elementDetails.length - 1].elementsDesc[0],
            0
          );
        } else {
          // for old-entry
          const newIndex = elementDetails[pageIndex].elementsDesc.length + 1;
          elementDetails[pageIndex].elementsDesc.push({
            type: "check",
            description: "",
            element: (
              <CheckBox
                key={newIndex}
                left={x}
                top={y}
                index={newIndex}
                onClickElement={onClickElement}
                selectedPageNo={selectedPageNo}
                handleChange={handleChange}
                deletefield={deletefield}
                role={user.roleList[0].role}
              />
            ),
            positionX: x,
            positionY: y,
            endUserInput: "",
            label: "",
            role: user.roleList[0].role,
            roleID: user.roleList[0].id,
            boxid: boxID,
            required: 1,
          });

          onSelectItem(
            elementDetails[pageIndex].elementsDesc[newIndex - 1],
            newIndex - 1
          );
        }

        break;

      case "radio":
        elementDetails = elements[2].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        if (pageIndex === -1) {
          // for new-entry
          elementDetails.push({
            pageNo: selectedPageNo,
            elementsDesc: [
              {
                type: "radio",
                description: "",
                element: (
                  <RadioBox
                    key={1}
                    left={x}
                    top={y}
                    index={1}
                    onClickElement={onClickElement}
                    selectedPageNo={selectedPageNo}
                    handleChange={handleChange}
                    deletefield={deletefield}
                    role={user.roleList[0].role}
                  />
                ),
                positionX: x,
                positionY: y,
                endUserInput: "",
                label: "",
                role: user.roleList[0].role,
                roleID: user.roleList[0].id,
                boxid: boxID,
                required: 1,
              },
            ],
          });

          onSelectItem(
            elementDetails[elementDetails.length - 1].elementsDesc[0],
            0
          );
        } else {
          // for old-entry
          const newIndex = elementDetails[pageIndex].elementsDesc.length + 1;
          elementDetails[pageIndex].elementsDesc.push({
            type: "radio",
            description: "",
            element: (
              <RadioBox
                key={newIndex}
                left={x}
                top={y}
                index={newIndex}
                onClickElement={onClickElement}
                selectedPageNo={selectedPageNo}
                handleChange={handleChange}
                deletefield={deletefield}
                role={user.roleList[0].role}
              />
            ),
            positionX: x,
            positionY: y,
            endUserInput: "",
            label: "",
            role: user.roleList[0].role,
            roleID: user.roleList[0].id,
            boxid: boxID,
            required: 1,
          });

          onSelectItem(
            elementDetails[pageIndex].elementsDesc[newIndex - 1],
            newIndex - 1
          );
        }

        break;
      default:
        break;
    }
  };

  const onDragOldItem = ({ type, index }, x, y) => {
    let elementDetails;
    let pageIndex;
    let eDesc;

    switch (type) {
      case "image":
        elementDetails = elements[4].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[index - 1];
        eDesc.element = (
          <SignatureBox
            key={index}
            left={x}
            top={y}
            index={index}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
            role={eDesc.role}
            payload={eDesc.labelID}
          />
        );
        eDesc.positionX = x;
        eDesc.positionY = y;
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[index - 1],
          index - 1
        );

        break;

      case "input":
        elementDetails = elements[0].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[index - 1];
        eDesc.element = (
          <InputBox
            key={index}
            left={x}
            top={y}
            index={index}
            role={eDesc.role}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
            payload={eDesc.labelID}
          />
        );
        eDesc.positionX = x;
        eDesc.positionY = y;
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[index - 1],
          index - 1
        );

        break;
      case "date-input":
        elementDetails = elements[3].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[index - 1];
        eDesc.element = (
          <InputBox
            key={index}
            left={x}
            top={y}
            index={index}
            role={eDesc.role}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
            payload={eDesc.labelID}
            isdate="true"
          />
        );
        eDesc.positionX = x;
        eDesc.positionY = y;
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[index - 1],
          index - 1
        );

        break;

      case "check":
        elementDetails = elements[1].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[index - 1];
        eDesc.element = (
          <CheckBox
            key={index}
            left={x}
            top={y}
            index={index}
            role={eDesc.role}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
          />
        );
        eDesc.positionX = x;
        eDesc.positionY = y;
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[index - 1],
          index - 1
        );

        break;

      case "radio":
        elementDetails = elements[2].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[index - 1];
        eDesc.element = (
          <RadioBox
            key={index}
            left={x}
            top={y}
            index={index}
            role={eDesc.role}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
          />
        );
        eDesc.positionX = x;
        eDesc.positionY = y;
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[index - 1],
          index - 1
        );

        break;
      default:
        break;
    }
  };

  const onSelectItem = (info, index) => {
    setSelectedItemInfo({
      info,
      index,
    });
  };

  const onChangeElementData = (elementType, propertyType, index, data) => {
    let elementDetails;
    let pageIndex;
console.log(elementType, propertyType, index, data, elementDetails, "elemdata");
    switch (elementType) {
      case "input":
        elementDetails = elements[0].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        
        elementDetails[pageIndex].elementsDesc[index][propertyType] = data;
        if (propertyType === "role" && data !== "sender") {
          console.log(
            "HI",
            "elemdata"
          );
          elementDetails[pageIndex].elementsDesc[index]["endUserInput"] = "";
        }
        setElements([...elements]);
        onSelectItem(elementDetails[pageIndex].elementsDesc[index], index);
        break;
      case "image":
        elementDetails = elements[4].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        elementDetails[pageIndex].elementsDesc[index][propertyType] = data;
        if (propertyType === "role" && data !== "sender") {
          console.log("HI", "elemdata");
          elementDetails[pageIndex].elementsDesc[index]["endUserInput"] = "";
        }
        setElements([...elements]);
        onSelectItem(elementDetails[pageIndex].elementsDesc[index], index);
        break;
      case "date-input":
        elementDetails = elements[3].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );

        elementDetails[pageIndex].elementsDesc[index][propertyType] = data;
        if (propertyType === "role" && data !== "sender") {
          console.log("HI", "elemdata");
          elementDetails[pageIndex].elementsDesc[index]["endUserInput"] = "";
        }
        setElements([...elements]);
        onSelectItem(elementDetails[pageIndex].elementsDesc[index], index);
        break;
      case "check":
        elementDetails = elements[1].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        elementDetails[pageIndex].elementsDesc[index][propertyType] = data;
        setElements([...elements]);
        onSelectItem(elementDetails[pageIndex].elementsDesc[index], index);
        break;

      case "radio":
        elementDetails = elements[2].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        elementDetails[pageIndex].elementsDesc[index][propertyType] = data;
        setElements([...elements]);
        onSelectItem(elementDetails[pageIndex].elementsDesc[index], index);
        break;
      default:
        break;
    }
  };

  const onClickElement = (type, elementIndex) => {
    let elementDetails;
    let pageIndex;
    let eDesc;

    switch (type) {
      case "input":
        elementDetails = elements[0].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];
        console.log(elementDetails, eDesc, pageIndex, "click");
        eDesc.element = (
          <InputBox
            key={elementIndex}
            left={eDesc.positionX}
            top={eDesc.positionY}
            role={eDesc.role}
            index={elementIndex}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
            payload={eDesc.labelID}
          />
        );
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[elementIndex - 1],
          elementIndex - 1
        );
        break;
      case "image":
        elementDetails = elements[4].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];

        eDesc.element = (
          <SignatureBox
            key={elementIndex}
            left={eDesc.positionX}
            top={eDesc.positionY}
            role={eDesc.role}
            index={elementIndex}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
            payload={eDesc.labelID}
          />
        );
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[elementIndex - 1],
          elementIndex - 1
        );
        break;
      case "date-input":
        elementDetails = elements[3].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];

        eDesc.element = (
          <InputBox
            key={elementIndex}
            left={eDesc.positionX}
            top={eDesc.positionY}
            role={eDesc.role}
            index={elementIndex}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
            payload={eDesc.labelID}
            isdate="true"
          />
        );
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[elementIndex - 1],
          elementIndex - 1
        );
        break;
      case "check":
        elementDetails = elements[1].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];
        eDesc.element = (
          <CheckBox
            key={elementIndex}
            left={eDesc.positionX}
            top={eDesc.positionY}
            index={elementIndex}
            role={eDesc.role}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
            payload={eDesc.labelID}
          />
        );
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[elementIndex - 1],
          elementIndex - 1
        );
        break;

      case "radio":
        elementDetails = elements[2].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];
        eDesc.element = (
          <RadioBox
            key={elementIndex}
            left={eDesc.positionX}
            top={eDesc.positionY}
            index={elementIndex}
            role={eDesc.role}
            onClickElement={onClickElement}
            selectedPageNo={selectedPageNo}
            handleChange={handleChange}
            deletefield={deletefield}
            payload={eDesc.labelID}
          />
        );
        onSelectItem(
          elementDetails[pageIndex].elementsDesc[elementIndex - 1],
          elementIndex - 1
        );
        break;
      default:
        break;
    }
  };

  const handleChange = (type, elementIndex, data) => {
    let elementDetails;
    let pageIndex;
    let eDesc;

    switch (type) {
      case "image":
        elementDetails = elements[4].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];
        eDesc.endUserInput = data;
        setElements([...elements]);
        break;

      case "input":
        elementDetails = elements[0].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];
        eDesc.endUserInput = data;
        setElements([...elements]);
        break;

      case "date-input":
        elementDetails = elements[3].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];
        eDesc.endUserInput = data;
        setElements([...elements]);
        break;

      case "check":
        elementDetails = elements[1].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];
        eDesc.endUserInput = data;
        setElements([...elements]);
        break;

      case "radio":
        elementDetails = elements[2].elementDetails;
        pageIndex = elementDetails.findIndex(
          (pData) => pData.pageNo === selectedPageNo
        );
        eDesc = elementDetails[pageIndex].elementsDesc[elementIndex - 1];
        eDesc.endUserInput = data;
        setElements([...elements]);
        break;
      default:
        break;
    }
  };

  const getPdfFromBucket = async (filename) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: filename ? filename : parameter.filename,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        console.log("hello error", "USER", err);
      } else {
        let pdfBlob = new Blob([new Uint8Array(data.Body)], {
          type: "application/pdf",
        });
        window.open(URL.createObjectURL(pdfBlob), "_blank");
        // let pdfBlob = new Blob([new Uint8Array(data.Body)], {
        // const blob = new Blob([pdfBlob], {type: 'application/pdf'})

        // setImgSrc(URL.createObjectURL(pdfBlob))
        // downloadBlob(pdfBlob, `${template}`);
      }
    });
  };

  const onSelectPage = useCallback((pageNumber) => {
    selectedPageNo = pageNumber;
    setSelectedPageNo(pageNumber);
  }, []);
  console.log(elements, "elements");
  return (
    <div className="pdf-container">
      <div className="row">
        <div className="col-lg-6 custom-width-pdf">
          <div className="set-up-pdf" ref={myRef}>
            <div style={{ ...style.drop }} className="canvas-size" ref={drop}>
              {/* Input Boxes */}
              {elements[0].elementDetails.length > 0
                ? elements[0].elementDetails
                    ?.filter((pages) => pages.pageNo === selectedPageNo)[0]
                    ?.elementsDesc.map((elemnt) => elemnt.element)
                : null}
              {/* Check Boxes */}
              {elements[1].elementDetails.length > 0
                ? elements[1].elementDetails
                    ?.filter((pages) => pages.pageNo === selectedPageNo)[0]
                    ?.elementsDesc.map((elemnt) => elemnt.element)
                : null}
              {/* Radio Boxes */}
              {elements[2].elementDetails.length > 0
                ? elements[2].elementDetails
                    ?.filter((pages) => pages.pageNo === selectedPageNo)[0]
                    ?.elementsDesc.map((elemnt) => elemnt.element)
                : null}
              {/*date-input */}
              {elements[3].elementDetails.length > 0
                ? elements[3].elementDetails
                    ?.filter((pages) => pages.pageNo === selectedPageNo)[0]
                    ?.elementsDesc.map((elemnt) => elemnt.element)
                : null}
              {elements[4].elementDetails.length > 0
                ? elements[4].elementDetails
                    ?.filter((pages) => pages.pageNo === selectedPageNo)[0]
                    ?.elementsDesc.map((elemnt) => elemnt.element)
                : null}
              {fileInfo && <ViewPdf pageNumber={selectedPage} />}
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          {" "}
          <div className="pdfOptions">
            <div style={style.sideBarContainerright} className="shadow">
              <SidebarRight
                position={position}
                elementDesc={selectedItemInfo}
                onChangeElementData={onChangeElementData}
                selectedPage={selectedPage}
                pageNo={selectedPage}
                textEditable={pageState?.pageType === "fill-up" ? false : true}
                getPdfFromBucket={getPdfFromBucket}
                deletefield={deletefield}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          {" "}
          <div style={style.sideBarContainer} className="shadow">
            {fileInfo && (
              <SidebarLeft
                onSelectPage={onSelectPage}
                draggable={pageState?.pageType === "fill-up" ? false : true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SetUpPdf);

const style = {
  sideBarContainer: {
    border: "1px solid #cbcbcb",
    width: "100%",

    height: "100vh",
  },
  sideBarContainerright: {
    border: "1px solid #cbcbcb",
    width: "100%",

    // height: "100vh",
  },
  drop: {
    overflow: "auto",
    border: "5px solid blue",
    zIndex: 10,
    width: "fit-content",
  },
};
