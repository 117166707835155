import React, { useState, useContext, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./UploadPdfStyles/UploadPdf.css";
import Percentage from "../../components/Percentage/Percentage";
import { SetFileContext } from "../../contexts/SetFileContext";
import { UserContext } from "../../contexts/UserContext";
// import UserDetails from '../../components/UserDetails';
import FileThumbnail from "../../components/FileThumbnail";
import axiosInstance from "../../utils/axiosInstance";
import swal from "sweetalert";
import UploadImg from "../../assets/images/search.png";

function UploadPdf() {
  const navigate = useNavigate();
  const [fileSubmitted, setFileSubmitted] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [fileInfo, setFileInfo] = useContext(SetFileContext);
  const [user, setUser] = useContext(UserContext);
  const [template_name, setTemplate_name] = useState("");
  const onFileChoose = useCallback((e) => {
    setFileInfo((_) => e.target.files[0]);
    setTemplate_name(e.target.files[0].name.replace(".pdf", ""));
    setFileName(e.target.files[0].name);
  }, []);

  const cancelFile = () => {
    setFileInfo((_) => null);
    setTemplate_name("");
    setFileName("");
  };

  useEffect(() => {
    return () => setFileInfo(null);
  }, []);

  const onFileUpload = async (e) => {
    setFileSubmitted(true);
    const formData = new FormData();
    formData.append("file", fileInfo);
    formData.append("userId", sessionStorage.userId);
    formData.append("template_name", template_name);

    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}upload`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.loaded)
              )
            );
          },
        }
      );
      swal("Template Added Successfully", "", "success");
      setUser((prevData) => {
        return { ...prevData, file: { ...data } };
      });

      navigate(`/templates`);
      // navigate(`/set-up`, { state: { pageType: 'set-up' }})
    } catch (error) {
      setFileSubmitted(false);
    }
  };

  return (
    <div className="container">
      <div className="upload-file-container">
        <div className="pdf-view shadow">
          <FileThumbnail
            onFileChoose={onFileChoose}
            UploadImg={UploadImg}
            fileName={fileName}
            cancelFile={cancelFile}
          />
        </div>
        <form className="pdf-form">
          <label className="pdf-label">Pdf Upload Section</label>
          <div className="custom-file custom-file-new">
            <label for="customFile">
              <div className="clearfix"></div>
            </label>
          </div>
          <input
            type="text"
            value={template_name}
            placeholder="Template Name"
            className="pdf-template-name form-control"
            onChange={(e) => {
              let value = e.target.value;

              value = !value.replace(/\s/g, "").length ? "" : value;
              value = value.replace(/^\s+/, "").replace(/\s+/g, " ");
              setTemplate_name(value);
            }}
            min={1}
            max={50}
            accept="application/pdf"
            disabled={!!fileInfo === false}
          />
          <button
            type="button"
            className="btn btn-primary btn-block mt-4 upload-btn-template"
            onClick={() => {
              if (template_name !== "") {
                onFileUpload();
              } else {
                swal("Opps", "Template name is required", "warning");
              }
            }}
            disabled={fileSubmitted || fileInfo === null}
          >
            {fileSubmitted ? (
              <span className="spinner-template"></span>
            ) : (
              "Upload"
            )}
          </button>
          <button
            type="button"
            className="btn btn-primary btn-block mt-4"
            onClick={() => {
              navigate(-1);
            }}
            disabled={fileSubmitted}
          >
            Back
          </button>
        </form>
      </div>
    </div>
  );
}

export default React.memo(UploadPdf);
