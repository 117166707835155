import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./SetUpUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faBars,
  faTrash,
  faCheck,
  faXmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Form, FormikProvider, useFormik, Field, getIn } from "formik";
import { UserContext } from "../../contexts/UserContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import axiosInstance from "../../utils/axiosInstance";
import UserSub from "./userSub";
import swal from "sweetalert";
import { ElementContext } from "../../contexts/ElementContext";

function SetUpUser() {
  const navigate = useNavigate();

  const [user, setUser] = useContext(UserContext);
  const { state: pageState } = useLocation();
  const [senderList, setSenderList] = useState([]);
  const [elements, setElements] = useContext(ElementContext);
  const [addsignerData, setAddsignerData] = useState({
    name: "",
    order: "",
  });
  const [onlysigner, setOnlysigner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isadd, setIsadd] = useState(false);
  const { templateid } = useParams();
  useEffectOnce(() => {
    setUser((prevData) => ({
      ...prevData,
      roleList: [],
    }));
    fetchSignerList();
  }, []);

  const fetchSignerList = async (values) => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}signer-details-view`,
        { template_id: templateid, userID: sessionStorage?.userId }
      );
      if (data.statusCode === 200) {
        if (data.id.length === 0) {
          addSigner({ name: "sender", order: "1" });
        } else {
          setUser((prevData) => ({
            ...prevData,
            roleList: [...data.id],
          }));
          setLoading(false);
        }
      }
    } catch (err) {}
  };

  const addSigner = async (signerdata) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}signer-details-add`,
        {
          template_id: templateid,
          userID: sessionStorage?.userId,
          role: {
            name: signerdata.name,
            order: signerdata.order,
          },
        }
      );
      if (data.statusCode === 200) {
        setUser((prevData) => ({
          ...prevData,
          roleList: [...data.id],
        }));
        setIsadd(false);
        setLoading(false);
        setAddsignerData((prevData) => ({
          ...prevData,
          name: "",
          order: "",
        }));
      }
    } catch (err) {}
  };

  const nextPage = () => {
    if (onlysigner && user.roleList.length !== 0) {
      const templist = [];
      templist.push(user.roleList[0]);
      setUser((prevData) => ({
        ...prevData,
        roleList: [...templist],
      }));
      navigate(`/set-up`, {
        state: {
          pageType: "set-up",
          fileName: pageState.fileName,
          numberOfPages: pageState.numberOfPages,
        },
      });
    } else if (!onlysigner && user.roleList.length > 1) {
      navigate(`/set-up`, {
        state: {
          pageType: "set-up",
          fileName: pageState.fileName,
          numberOfPages: pageState.numberOfPages,
        },
      });
    } else {
      swal("Opps", "Add more roles", "warning");
    }
  };
  return (
    <>
      <div className="template-container">
        <div className="row">
          <div className="col-lg-12">
            <h5 className="mb-3">
              <input
                type="checkbox"
                className="mr-2"
                id="onlysigner"
                name="onlysigner"
                checked={onlysigner}
                onChange={(e) => {
                  setOnlysigner(e.target.checked);
                }}
              />
              I am the only signer
            </h5>
          </div>
        </div>

        {!onlysigner && (
          <div className="row">
            {(user.roleList || []).map((val, index) => {
              if (index !== 0) {
                return (
                  <UserSub
                    val={val}
                    index={index}
                    key={index}
                    setLoading={setLoading}
                  />
                );
              }
            })}
            <div className="col-lg-4 mb-4">
              {isadd && (
                <div className="card border-left-primary shadow">
                  <div className="card-body">
                    <h5>order: {addsignerData.order - 1}</h5>
                    <div className="form-group d-inline">
                      <div className="d-flex">
                        <input
                          type="text"
                          value={addsignerData.name}
                          name="role"
                          className="form-control"
                          placeholder="Designation"
                          onChange={(e) => {
                            let value = e.target.value.replace(
                              /[^a-zA-Z\s]/g,
                              ""
                            );

                            value = !value.replace(/\s/g, "").length
                              ? ""
                              : value;
                            value = value
                              .replace(/^\s+/, "")
                              .replace(/\s+/g, " ");
                            setAddsignerData((prevData) => ({
                              ...prevData,
                              name: value,
                            }));
                          }}
                          id="role"
                        />
                        <>
                          <a
                            className="text-success ml-2 mr-2 icon-size-user clickable"
                            onClick={() => {
                              if (addsignerData.name === "") {
                                swal(
                                  "Opps",
                                  "Roles Name is required",
                                  "warning"
                                );
                              } else {
                                const indexname = user.roleList.findIndex(
                                  (val, idx) =>
                                    val.role.toLowerCase() ===
                                    addsignerData.name.toLowerCase()
                                );
                                if (indexname !== -1) {
                                  swal("Opps", "Roles Names Exist", "warning");
                                } else {
                                  addSigner(addsignerData);
                                }
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </a>{" "}
                          <a
                            className="text-danger ml-2 mr-2 icon-size-user clickable"
                            onClick={() => {
                              setIsadd(false);
                              setAddsignerData((prevData) => ({
                                ...prevData,
                                name: "",
                                order: "",
                              }));
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </a>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`col-sm-4 ${
                  user.roleList.length > 1 ? "mt-4" : "pl-0"
                }`}
              >
                {!isadd && !onlysigner && (
                  <button
                    className={`btn btn-primary ${
                      user.roleList.length > 1 ? "mt-4" : ""
                    }`}
                    onClick={() => {
                      setIsadd(true);
                      setAddsignerData((prevData) => ({
                        ...prevData,
                        name: "",
                        order:
                          user.roleList[user.roleList.length - 1].order + 1,
                      }));
                    }}
                    type="button"
                    disabled={loading}
                  >
                    Add Signer
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        <button
          type="submit"
          className="btn btn-primary"
          disabled={loading}
          onClick={() => {
            nextPage();
          }}
        >
          Next
        </button>
        <button
          type="button"
          className="btn btn-primary ml-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </div>
    </>
  );
}

export default React.memo(SetUpUser);
