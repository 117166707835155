import React, {
    useState,
    createContext,
    useEffect
} from 'react'

export const SetFileContext = createContext();

function SetFileProvider(props) {

  const [fileInfo, setFileInfo] = useState(null);

  useEffect(() => {
  },[fileInfo])

  return (
    <SetFileContext.Provider value={[fileInfo, setFileInfo]}>
        { props.children }
    </SetFileContext.Provider>
  )
}

export default SetFileProvider