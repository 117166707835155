import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { setSession } from "../utils/token.js";
import axiosInstance from "../utils/axiosInstance";

const schema = yup
  .object({
    userEmail: yup
      .string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    userPassword: yup.string().required("Password is required"),
  })
  .required();

function UserDetails() {
  const navigate = useNavigate();
  // const [uDetails, setUDetails] = useState({
  //   email: "",
  //   password: "",
  // });

  const [user, setUser] = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    initialValues: {
      userEmail: "",
      userPassword: "",
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = async (dataValues) => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}user-login`,
        { email: dataValues.userEmail, password: dataValues.userPassword }
      );
      // swal("Something went wrong", "warning");
      if (data.access_token) {
        setSession(data.access_token);

        sessionStorage.userId = data?.userID;
        setUser((prevData) => ({ ...prevData, email: dataValues.userEmail }));
        navigate("/templates");
      }
    } catch (error) {
      swal("Opps", "Something went wrong", "warning");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="email1">Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Email"
          {...register("userEmail")}
          id="email1"
        />
        <p className="error-msg">{errors.userEmail?.message}</p>
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          {...register("userPassword")}
          id="password"
        />
        <p className="error-msg">{errors.userPassword?.message}</p>
      </div>
      <div className="form-group">
        <button type="submit" className="btn btn-primary text-center">
          Login
        </button>
      </div>
      <div className="row mt-3">
        <div className="col">
          *If you are a new user then{" "}
          <Link to="/registration" className="link-primary">
            click here
          </Link>
        </div>
      </div>
    </form>
  );
}

export default React.memo(UserDetails);
