import React, { useState, createContext } from "react";

export const ElementContext = createContext();

function ElementContextProvider(props) {
  const eDtls = [
  ];

  const elementList = [
    {
      id: 1,
      type: "input",
      elementDetails: [],
    },
    {
      id: 2,
      type: "check",
      elementDetails: [],
    },
    {
      id: 3,
      type: "radio",
      elementDetails: [],
    },
    {
      id: 4,
      type: "date-input",
      elementDetails: [],
    },
    {
      id: 5,
      type: "image",
      elementDetails: [],
    },
  ];

  const [elements, setElements] = useState(elementList);

  return (
    <ElementContext.Provider value={[elements, setElements]}>
      {props.children}
    </ElementContext.Provider>
  );
}

export default ElementContextProvider;
