import React, { useContext, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { ElementContext } from "../../contexts/ElementContext";
import { UserContext } from "../../contexts/UserContext";
import "./SidebarRight.css";
import axiosInstance from "../../utils/axiosInstance";
import SquareBoxDraggable from "../SquareBoxDraggable";
import CheckBoxDraggable from "../CheckBoxDraggable";
import RadioBoxDraggable from "../RadioBoxDraggable";
import SquareBoxDraggableName from "../SquareBoxDraggableName";
import SquareBoxDraggableEmail from "../SquareBoxDraggableEmail";
import SignatureBoxDraggable from "../SignatureBoxDraggable";
import DateBoxDraggable from "../DateBoxDraggable";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// function SidebarRight({ elementDesc, onChangeElementData, textEditable = true, pageNo, getPdfFromBucket }) {
import downArrow from "../../assets/images/211687_down_arrow_icon.png";
import upArrow from "../../assets/images/211690_up_arrow_icon.png";
import SquareBoxDraggableCompany from "../SquareBoxDraggableCompany";
import SquareBoxDraggableTitle from "../SquareBoxDraggableTitle";
import SquareBoxDraggableInitial from "../SquareBoxDraggableInitial";
import SquareBoxDraggableGender from "../SquareBoxDraggableGender";
import SquareBoxDraggableAddress from "../SquareBoxDraggableAddress";
import SquareBoxDraggablePhone from "../SquareBoxDraggablePhone";
function SidebarRight({
  elementDesc,
  onChangeElementData,
  textEditable = true,
  pageNo,
  getPdfFromBucket,
  position,
  deletefield,
}) {
  const [elements, setElements] = useContext(ElementContext);
  const [user, setUser] = useContext(UserContext);
  const parameter = useParams();
  const { state: pageState } = useLocation();
  const loc = useLocation();
  const navigate = useNavigate();
  const [accordiandetails, setAccordiandetails] = useState(true);
  const [accordianuser, setAccordianuser] = useState(true);
  const [loader, setLoader] = useState(false);

  const toTitleCase = (type) => {
    switch (type) {
      case "input":
        return `Input - ${elementDesc?.index + 1}`;
      case "check":
        return `Check - ${elementDesc?.index + 1}`;
      case "radio":
        return `Radio - ${elementDesc?.index + 1}`;
      default:
        return "No item selected";
    }
  };
  const onSubmit = () => {
    if (loc.pathname.includes("/fill-up")) {
      setLoader(true);
      if (user.roleList.length === 0) {
        submitRawInputs();
      } else {
        if (user.roleList.length === 1) {
          submitpdfsender();
        } else {
          submitRawInputs();
        }
      }

      // if (user.roleList[0].guestrole === "self") {
      // }
    } else {
      navigate("/fill-up", {
        state: {
          pageType: "fill-up",
          fileName: pageState.fileName,
          numberOfPages: pageState.numberOfPages,
        },
      });
    }
  };

  console.log(pageState);

  const submitRawFilterInput = async (guestid) => {
    const elementData = JSON.parse(JSON.stringify(elements));
    const payloaddata = {
      userID: sessionStorage?.userId,
      guestId: guestid,
      template_id: user.template_id,
      payload: elementData?.map((element) => {
        element.elementDetails = element.elementDetails
          .filter((pages) => !!pages.pageNo)
          .map((pDetails) => ({
            pageNo: pDetails.pageNo,
            elementsDesc: pDetails.elementsDesc.map((eDetails) => ({
              type: eDetails?.type,
              description: eDetails?.description,
              positionX: eDetails?.positionX, //left
              positionY: eDetails?.positionY, //top
              endUserInput: eDetails?.endUserInput,
              role: eDetails?.role,
              roleID: eDetails?.roleID,
              boxid: eDetails?.boxid,
              required: eDetails?.required,
              label: eDetails?.label,
              labelID: eDetails?.labelID,
            })),
          }));
        return element;
      }),
    };

    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}field-details`,
        payloaddata
      );
      if (data.status) {
        swal("Good job!", "Template created successfully", "success");
        navigate("/templates");
      }
    } catch (error) {
      setLoader(false);
      swal("Error", "Make sure all the required fields are filled", "warning");
    }
  };

  const submitpdfsender = async (guestid) => {
    const elementData = JSON.parse(JSON.stringify(elements));
    const arraygen = elementData?.map((element) => {
      element.elementDetails = element.elementDetails
        .filter((pages) => !!pages.pageNo)
        .map((pDetails) => ({
          pageNo: pDetails.pageNo,
          elementsDesc: pDetails.elementsDesc.map((eDetails) => ({
            type: eDetails?.type,
            description: eDetails?.description,
            positionX: eDetails?.positionX, //left
            positionY: eDetails?.positionY, //top
            endUserInput: eDetails?.endUserInput,
            role: eDetails?.role,
            roleID: eDetails?.roleID,
            boxid: eDetails?.boxid,
            required: eDetails?.required,
            label: eDetails?.label,
            labelID: eDetails?.labelID,
            height: 30,
            width: 50,
          })),
        }));
      return element;
    });
    const payloaddata = [];
    Array.from(Array(pageState.numberOfPages).keys()).map((val, index) =>
      payloaddata.push({
        pageNo: index + 1,
        elementDetails: [],
      })
    );

    arraygen.map((val, id) => {
      if (val.elementDetails.length !== 0) {
        val.elementDetails.map((elem, idx) => {
          // let temppayloaddata = [...payloaddata];
          let indextemp = payloaddata.findIndex(
            (value) => value.pageNo === elem.pageNo
          );
          payloaddata[indextemp].elementDetails.push(...elem.elementsDesc);
          console.log(elem, "abc");
        });
      }
    });

    console.log(payloaddata, arraygen, "paydata");

    const paylod = {
      uid: sessionStorage?.userId,
      guestId: guestid,
      contractid:
        sessionStorage.getItem("contractid") !== null
          ? sessionStorage.getItem("contractid")
          : "",
      storedFilename: user?.fileName,
      template_id: user.template_id,
      payload: [...payloaddata],
    };
    // rawpayload: elementData,
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}pdf-processing-sender`,
        paylod
      );
      setUser({ ...user, ...data });
      if (data.status) {
        getPdfFromBucket(data.finalFileName);
        swal("Good job!", "Pdf has been processed successfully", "success");
        navigate("/templates");
      } else {
        setLoader(false);
        swal(
          "Error",
          "Make sure all the required fields are filled",
          "warning"
        );
      }
    } catch (error) {
      setLoader(false);
      swal("Error", "Something went wrong", "warning");
    }
  };

  console.log(sessionStorage.getItem("contractid"), "idcontract");

  const submitInputs = async (guestid) => {
    const elementData = JSON.parse(JSON.stringify(elements));
    const arraygen = elementData?.map((element) => {
      element.elementDetails = element.elementDetails
        .filter((pages) => !!pages.pageNo)
        .map((pDetails) => ({
          pageNo: pDetails.pageNo,
          elementsDesc: pDetails.elementsDesc
            .filter((details) => {
              console.log(details, "details");
              return details.role.toLowerCase() === user.role.toLowerCase();
            })
            .map((eDetails) => ({
              type: eDetails?.type,
              description: eDetails?.description,
              positionX: eDetails?.positionX, //left
              positionY: eDetails?.positionY, //top
              endUserInput: eDetails?.endUserInput,
              role: eDetails?.role,
              roleID: eDetails?.roleID,
              boxid: eDetails?.boxid,
              required: eDetails?.required,
              label: eDetails?.label,
              labelID: eDetails?.labelID,
              height: 30,
              width: 50,
            })),
        }));

      return element;
    });
    const payloaddata = [];
    const temppayloaddata = [];

    Array.from(Array(parseInt(pageState.numberOfPages)).keys()).map(
      (val, index) =>
        payloaddata.push({
          pageNo: index + 1,
          elementDetails: [],
        })
    );

    console.log(payloaddata, pageState.numberOfPages, "no of pages");
    arraygen.map((val, id) => {
      if (val.elementDetails.length !== 0) {
        val.elementDetails.map((elem, idx) => {
          // let temppayloaddata = [...payloaddata];
          let indextemp = payloaddata.findIndex((value) => {
            console.log(value.pageNo, elem.pageNo, "abc");

            return value.pageNo === elem.pageNo;
          });
          console.log(payloaddata, indextemp, elem, "abc");
          payloaddata[indextemp].elementDetails.push(...elem.elementsDesc);
        });
      }
    });

    console.log(payloaddata, arraygen, "paydata");
    const paylod = {
      uid: sessionStorage?.userId,
      guestId: guestid,
      contractid:
        sessionStorage.getItem("contractid") !== null
          ? sessionStorage.getItem("contractid")
          : "",
      storedFilename: user?.fileName,
      template_id: user.template_id,
      payload: [...payloaddata],
    };
    // rawpayload: elementData,
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}pdf-signature-text-processing`,
        paylod
      );
      setUser({ ...user, ...data });
      if (data.status) {
        getPdfFromBucket(data.finalFileName);
        setLoader(false);

        // swal("Good job!", "Field processed successfully", "success");
        navigate("/usersuccess");
      }else{
        setLoader(false);
        swal(
          "Error",
          "Make sure all the required fields are filled",
          "warning"
        );
      }
    } catch (error) {
      setLoader(false);
      swal("Error", "Make sure all the required fields are filled", "warning");
    }
  };
  // final-pdf-guest
  const submitRawInputs = async () => {
    const elementData = JSON.parse(JSON.stringify(elements));
    const paylod = {
      uid: sessionStorage?.userId,
      storedFilename: user?.fileName,
      rawpayload: elementData,
    };
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}raw-payload`,
        paylod
      );
      if (data.status) {
        if (parameter.encrypteddata === undefined) {
          submitRawFilterInput(data.guestId);
        } else {
          submitInputs(data.guestId);
        }
      }
    } catch (error) {
      setLoader(false);
      swal("Error", "Make sure all the required fields are filled", "warning");
    }
  };
  console.log(elementDesc, "desc");
  return (
    <div className="desc-container">
      {loc.pathname.includes("/set-up") && (
        <div className="fieldscss">
          <div className="row">
            <div className="col">
              <h6 className="ml-0">Fields</h6>
            </div>
            <div className="col text-right">
              <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  onSubmit();
                  setAccordianuser(false);
                  setAccordiandetails(false);
                }}
              >
                Next
              </button>
            </div>
          </div>
          <ul>
            <li>
              <SquareBoxDraggableName />
            </li>
            <li>
              <SquareBoxDraggableEmail />
            </li>
            <li>
              <SquareBoxDraggable />
            </li>
            {/* <li>
              <CheckBoxDraggable />
            </li>
            <li>
              <RadioBoxDraggable />
            </li> */}
            <li>
              <SquareBoxDraggableTitle />
            </li>
            <li>
              <SquareBoxDraggableInitial />
            </li>
            <li>
              <SquareBoxDraggableGender />
            </li>
            <li>
              <SquareBoxDraggableAddress />
            </li>
            <li>
              <SquareBoxDraggablePhone />
            </li>
            <li>
              <SquareBoxDraggableCompany />
            </li>
            <li>
              <SignatureBoxDraggable />
            </li>
            <li>
              <DateBoxDraggable />
            </li>
          </ul>
        </div>
      )}
      {elementDesc !== null && (
        <div className="fieldscss">
          <div className="row">
            <div className="col">
              <h6 className="ml-0">Selected Field</h6>
            </div>
            <div className="col text-right">
              {loc.pathname.includes("/set-up") && (
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    console.log("hi");
                    deletefield(elementDesc?.index, elementDesc?.info?.type);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                // </div>
              )}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12 text-right">
              {loc.pathname.includes("/set-up") && (
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    console.log("hi");
                    deletefield(elementDesc?.index, elementDesc?.info?.type);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                // </div>
              )}
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item pb-3">
                <h5 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button shadow"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                    onClick={() => setAccordiandetails(!accordiandetails)}
                  >
                    Details
                    <div className="float-right">
                      {accordiandetails ? (
                        <img src={upArrow} width="20px" height="20px" alt="" />
                      ) : (
                        <img
                          src={downArrow}
                          width="20px"
                          height="20px"
                          alt=""
                        />
                      )}
                    </div>
                  </button>
                </h5>
                <div
                  id="panelsStayOpen-collapseOne"
                  className={`accordion-collapse collapse ${
                    accordiandetails ? "show" : ""
                  }`}
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <p className="description">
                        <strong>Page:</strong> {pageNo}
                      </p>
                    </div>
                    <div className="row">
                      <strong className="mb-3">Description: &nbsp;</strong>
                      {!loc.pathname.includes("fill-up") ? (
                        <textarea
                          className="form-control"
                          value={elementDesc?.info?.description}
                          onChange={(e) =>
                            onChangeElementData(
                              elementDesc.info.type,
                              "description",
                              elementDesc.index,
                              e.target.value
                            )
                          }
                          style={{ height: "153px" }}
                        ></textarea>
                      ) : (
                        <p>{elementDesc?.info?.description}</p>
                      )}
                    </div>
                    <div className="row">
                      <strong className="mb-2">Label: &nbsp;</strong>
                      {!loc.pathname.includes("fill-up") ? (
                        <input
                          className="form-control"
                          value={elementDesc?.info?.label || ""}
                          onChange={(e) =>
                            onChangeElementData(
                              elementDesc.info.type,
                              "label",
                              elementDesc.index,
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <p>{elementDesc?.info?.label}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item pb-3">
                <h5 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button collapsed shadow"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                    onClick={() => setAccordianuser(!accordianuser)}
                  >
                    User
                    <div className="float-right">
                      {accordianuser ? (
                        <img src={upArrow} width="20px" height="20px" alt="" />
                      ) : (
                        <img
                          src={downArrow}
                          width="20px"
                          height="20px"
                          alt=""
                        />
                      )}
                    </div>
                  </button>
                </h5>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className={`accordion-collapse collapse ${
                    accordianuser ? "show" : ""
                  }`}
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body">
                    <div className="row">
                      {loc.pathname.includes("fill-up") ? (
                        <>
                          <strong className="mb-2">User: &nbsp;</strong>
                          {elementDesc?.info?.role}
                        </>
                      ) : (
                        <>
                          {user.roleList.length !== 0 &&
                            elementDesc?.info?.role && (
                              <>
                                <label htmlFor="userrole">
                                  <strong className="mb-2">User:</strong>
                                </label>
                                <select
                                  name="userrole"
                                  id="userrole"
                                  value={elementDesc.info.roleID}
                                  className="select-role form-control"
                                  onChange={(e) => {
                                    let indexid = user.roleList.findIndex(
                                      (val, idx) =>
                                        val.id.toString() === e.target.value
                                    );
                                    onChangeElementData(
                                      elementDesc.info.type,
                                      "roleID",
                                      elementDesc.index,
                                      e.target.value.toString()
                                    );
                                    onChangeElementData(
                                      elementDesc.info.type,
                                      "role",
                                      elementDesc.index,
                                      user.roleList[indexid].role
                                    );
                                  }}
                                >
                                  {user.roleList.map((val, idx) => (
                                    <option value={val.id} key={idx}>
                                      {val.role}
                                    </option>
                                  ))}
                                </select>
                              </>
                            )}
                        </>
                      )}
                    </div>
                    <div className="row mt-4">
                      {loc.pathname.includes("fill-up") ? (
                        <>
                          <strong className="mb-2">Required: &nbsp;</strong>
                          {elementDesc?.info?.required === 1 ? "true" : "false"}
                        </>
                      ) : (
                        <>
                          <label className="check-container">
                            Required
                            <input
                              type="checkbox"
                              checked={
                                elementDesc.info.required === 1 ? true : false
                              }
                              onChange={(e) => {
                                onChangeElementData(
                                  elementDesc?.info?.type,
                                  "required",
                                  elementDesc.index,
                                  e.target.checked === true ? 1 : 0
                                );
                              }}
                              className="mr-2"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!loc.pathname.includes("/set-up") && (
        <div className="row">
          <div className="col-lg-12 text-center">
            <button
              className="btn btn-primary mb-2"
              disabled={loader}
              onClick={onSubmit}
            >
              {loader ? <span className="spinner-pdf-submit"></span> : "Submit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SidebarRight;
