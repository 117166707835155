import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AWS from "../../configs/AWS";
import { SetFileContext } from "../../contexts/SetFileContext";
import axios from "axios";
import "./Templates.css";
import { UserContext } from "../../contexts/UserContext";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import axiosInstance from "../../utils/axiosInstance";
import { setSession } from "../../utils/token";
import { format } from "date-fns";
import TemplatesSub from "./TemplatesSub";

function Templates() {
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const [fileInfo, setFileInfo] = useContext(SetFileContext);
  const [templates, setTemplates] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const { accesstoken } = useParams();
  const location = useLocation();
  useEffectOnce(() => {
    if (accesstoken) {
      decryptData();
    } else {
      fetchData();
    }
  }, [location]);

  const decryptData = async () => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}decrypt-login-data`,
        { encryptedData: accesstoken }
      );
      if (data.status) {
        let tempdata = JSON.parse(data.decryptedData || {});
        sessionStorage.setItem("userId", tempdata.userID);
        // sessionStorage.userId = data?.userID;
        setSession(tempdata.access_token);
        setUser((prevData) => ({ ...prevData, email: tempdata.email }));
        navigate(`/templates`);
      } else {
      }

      // navigate(`/set-up`, { state: { pageType: 'set-up' }})
    } catch (error) {
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}userfile-list`,
        { userId: sessionStorage?.userId }
      );
      if (data[1].statusCode === 200) {
        setTemplates(data[0]);
        setLoading(false);
      } else {
      }

      // navigate(`/set-up`, { state: { pageType: 'set-up' }})
    } catch (error) {
      setLoading(false);
    }
  };

  const onClickSetUp = (s3FileName, numberOfPages,template_id) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: s3FileName,
    };
    setUser((prevData) => {
      return { ...prevData, fileName: s3FileName, template_id: template_id };
    });
    s3.getObject(params, (err, data) => {
      if (err) {
      } else {
        let pdfBlob = new Blob([new Uint8Array(data.Body)], {
          type: "application/pdf",
        });

        setFileInfo(pdfBlob);
        navigate(`/set-user/${template_id}`, {
          state: {
            pageType: "set-up",
            fileName: s3FileName,
            numberOfPages: numberOfPages,
          },
        });
      }
    });
  };

  return (
    <>
      {accesstoken ? (
        <div className="template-container">
          <div className="overlay">
            <div className="overlay__inner">
              <div className="overlay__content">
                <span className="spinner"></span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="template-container">
          <div className="tblDv">
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="topBtnDv mb-4">
                  <div className="row">
                    <div className="col-12 text-right">
                      <a
                        className="btn btn-primary btn-update btn-sm ntBtn add-tmplte-btn"
                        onClick={() => navigate("/upload-pdf")}
                      >
                        + Add Template
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <div
                    id="tableID_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    {/* <div className="dataTables_length">
                      <label>
                        Show
                        <select
                          name="tableID_length"
                          aria-controls="tableID"
                          className=""
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </label>
                    </div> */}

                    <div className="dataTables_filter">
                      <label>
                        Search:
                        <input
                          type="search"
                          className=""
                          placeholder="search"
                          aria-controls="tableID"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </label>
                    </div>

                    <table
                      id="sortTable"
                      className="table table-bordered table table-bordered da-table cusTable dataTable no-footer"
                    >
                      <thead>
                        <tr>
                          <th>Sl No</th>
                          <th>Template Id</th>
                          <th>Template Name</th>
                          <th>Creation Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <span className="spinner"></span>
                        ) : (
                          <>
                            {templates
                              .filter((val) => val.filename.includes(search))
                              .map((template, index) => (
                                <TemplatesSub
                                  key={index}
                                  index={index}
                                  template={template}
                                  onClickSetUp={onClickSetUp}
                                />
                              ))}
                          </>
                        )}
                      </tbody>
                    </table>
                    <div
                      className="dataTables_info"
                      id="tableID_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 1 to 1 of 1 entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="tableID_paginate"
                    >
                      <a
                        className="paginate_button previous disabled"
                        aria-controls="tableID"
                        id="tableID_previous"
                      >
                        Previous
                      </a>
                      <span>
                        <a
                          className="paginate_button current"
                          aria-controls="tableID"
                        >
                          1
                        </a>
                      </span>
                      <a
                        className="paginate_button next disabled"
                        aria-controls="tableID"
                        id="tableID_next"
                      >
                        Next
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(Templates);

// {accesstoken ? (
//   <div className="template-container">...checking</div>
// ) : (
//   <div className="template-container">
//     <div className="row mb-2 ml-0">
//       <button
//         className="btn btn-primary"
//         onClick={() => navigate("/upload-pdf")}
//       >
//         New Template
//       </button>
//     </div>
//     <div className="row">
//       {templates.map((template, index) => {
//         return (
//           <div className="col-sm-4 mb-4" key={index}>
//             <div className="card">
//               <div className="card-body">
//                 <h5 className="card-title">File: {template?.filename}</h5>
//                 <p className="card-text">
//                   Date: {template?.created_date.slice(0, 16)}
//                 </p>
//                 <button
//                   onClick={() =>
//                     onClickSetUp(
//                       template?.storedFilename,
//                       template?.pageNo
//                     )
//                   }
//                   className="btn btn-primary"
//                 >
//                   Set up
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   </div>
// )}
