import React, { Component } from "react";
import ReactDOM from "react-dom";
import SignaturePad from "react-signature-canvas";

import styles from "./styles.module.css";

export default class SignatureComponent extends Component {
  state = { trimmedDataURL: null };
  sigPad = {};
  clear = () => {
    this.sigPad.clear();
  };
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });
    this.props.handleChange(
      "image",
      this.props.index,
      this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    );
    this.props.setOpen(false);
  };
  render() {
    let { trimmedDataURL } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.sigContainer}>
          <SignaturePad
            canvasProps={{ className: styles.sigPad }}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        </div>
        {trimmedDataURL ? (
          <img className={styles.sigImage} src={trimmedDataURL} />
        ) : null}
        <div>
          <button
            className={`btn btn-primary mt-1 ${styles.buttons}`}
            onClick={this.trim}
          >
            Save
          </button>
          <button
            className={`btn btn-primary mt-1 ${styles.buttons}`}
            onClick={this.clear}
          >
            Clear
          </button>
          <button
            className={`btn btn-primary mt-1 ${styles.buttons}`}
            onClick={() => this.props.setOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}
