import React, { useContext, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { useParams } from "react-router-dom";
import { ElementContext } from "../contexts/ElementContext";
import { UserContext } from "../contexts/UserContext";

/**
 * Your Component
 */
function RadioBox({
  left,
  top,
  index,
  onClickElement,
  selectedPageNo,
  handleChange,
  role
}) {
 const [elements, setElements] = useContext(ElementContext);
 const [user, setUser] = useContext(UserContext);
  const parameter = useParams();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "radio-box",
    item: {
      type: "radio",
      left,
      top,
      index,
      position: "old",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

     const disableInput = () => {
       if (user.role.toLowerCase() === role.toLowerCase()) {
         return false;
       } else {
         return true;
       }
     };
  return (
    <>
      <input
        ref={drag}
        type="radio"
        className="form-control"
        onClick={(e) => onClickElement("radio", index)}
        onChange={(e) => handleChange("radio", index, e.target.value)}
        value={
          elements[0].elementDetails[
            elements[0].elementDetails?.findIndex(
              (pData) => pData.pageNo === selectedPageNo
            )
          ]?.elementsDesc[index - 1]?.endUserInput || ""
        }
        style={{ ...styles.radio, left, top }}
      />
    </>
  );
}

const styles = {
  radio: {
    background: "transparent",
    zIndex: 1,
    position: "absolute",
    margin: "-3px",
    border: "1px solid black",
    height: "16px",
    width: "60px",
    padding: "5px",
    width: "fit-content",
  },
};

export default RadioBox;
