import React, { useState, createContext } from "react";

export const UserContext = createContext();

function UserContextProvider(props) {
  const initialValues = {
    email: "",
    role: "sender",
    name: "",
    file: null,
    fileName: "",
    template_id: "",
    roleList: [],
  };
  const [user, setUser] = useState(initialValues);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
