import React, { useContext, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosInstance from "../utils/axiosInstance";

const schema = yup
  .object({
    userName: yup.string().required("Name is required"),
    userEmail: yup
      .string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    userPassword: yup.string().required("Password is required"),
  })
  .required();

function UserRegistation() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    initialValues: {
      userName: "",
      userEmail: "",
      userPassword: "",
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = async (dataValues) => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_HOST}user-registration`,
        {
          email: dataValues.userEmail,
          password: dataValues.userPassword,
          name: dataValues.userName,
        }
      );

      navigate("/login");
    } catch (error) {
      swal("Opps", "Something went wrong", "warning");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Name"
          {...register("userName")}
          id="name"
        />
        <p className="error-msg">{errors.userName?.message}</p>
      </div>
      <div className="form-group">
        <label htmlFor="email1">Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Email"
          {...register("userEmail")}
          id="email1"
        />
        <p className="error-msg">{errors.userEmail?.message}</p>
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          {...register("userPassword")}
          id="password"
        />
        <p className="error-msg">{errors.userPassword?.message}</p>
      </div>
      <div className="form-group">
        <button type="submit" className="btn btn-primary text-center">
          Register
        </button>
      </div>
      <div className="row mt-3">
        <div className="col">
          *Already have an account? then{" "}
          <Link to="/login" className="link-primary">
            click here
          </Link>
        </div>
      </div>
    </form>
  );
}

export default React.memo(UserRegistation);
