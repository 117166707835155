export default function lazyloaddata(dataSet) {
  const groupData = (d) => {
    let g = d.reduce(
      (r, c) => {
        //r-->{},c-->element
        return (
          r.pdfdata.push({
            name: c.type,
            children: [...groupDataarrange(c.elementDetails)],
          }),
          r
        );
        // return (r[c.type] = [...(r[c.type] || []), c.elementDetails]), r;
      },
      { pdfdata: [] }
    );
    return g.pdfdata;
  };

  const groupDataarrange = (d) => {
    let g = [];
    let h =[];
    const groupDataarrangesub = (dsub) => {
      let gsub = [];
      dsub.elementsDesc.forEach((element) => {
        gsub.push({ pageNo: dsub.pageNo, ...element });
      });
      return gsub;
    };

    d.forEach((element) => {
      if (element.pageNo !== "") {
        g.push({ element: [...groupDataarrangesub(element)] });
      }
    });
    
    g.forEach(elem=>{
      h.push(...elem.element)
    })
    return h;
  };

  
  let tempdata = [...groupData(dataSet)];
  return tempdata;
}
  
