import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ViewPdf from "../ViewPdf/ViewPdf";
import "./SidebarLeft.css";
import { UserContext } from "../../contexts/UserContext";
import { SetFileContext } from "../../contexts/SetFileContext";

function SidebarLeft({ onSelectPage, draggable = false }) {
  const { state: pageState } = useLocation();
  const loc = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);
  const [pageArr, setPageArr] = useState([]);
  const [fileInfo, setFileInfo] = useContext(SetFileContext);
  useEffect(() => {
    setPageArr((prevData) => {
      for (let i = 0; i < pageState?.numberOfPages; i++) {
        if (prevData.indexOf(i) === -1) {
          prevData.push(i);
        }
      }
      return [...prevData];
    });
  }, []);
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="side-pdf-list">
        <div className="row">
          <div className="col">
            <h6>Pages</h6>
          </div>
          <div className="col text-right">
            <button
              className="btn btn-primary mt-3 mr-2"
              onClick={() => {navigate(-1);
              }}
              style={{zIndex:"999"}}
            >
             Back
            </button>
          </div>
        </div>

        {pageArr.map((element, index) => {
          const pageNo = element + 1;
          return (
            <div
              key={index}
              className="side-pdf-list-item"
              value={pageNo}
              onClick={(e) => onSelectPage(pageNo)}
            >
              <div className="text-content">
                {fileInfo && <ViewPdf pageNumber={pageNo} />}
              </div>
              <div className="page-no">{pageNo}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(SidebarLeft);
