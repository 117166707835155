import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Templates.css";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faBars ,faTrash} from "@fortawesome/free-solid-svg-icons";

function TemplatesSub({index, template, onClickSetUp}) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { accesstoken } = useParams();
  const location = useLocation();
  return (
    <tr key={index}>
      <td>{index}</td>
      <td>{template.template_id}</td>
      <td>{template.template_name}</td>
      <td>{format(new Date(template.created_date), "do MMM yyyy")}</td>
      <td className="actionCont">
        <div className="dropdown">
          <a
            className="dropdown-toggle clickable"
            type="button"
            data-toggle="dropdown"
            onClick={() => {
              setShow(!show);
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </a>
          {show && (
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                // href="#"
                onClick={() =>
                  onClickSetUp(
                    template?.storedFilename,
                    template?.pageNo,
                    template?.template_id
                  )
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                Edit Template
              </a>
              {/* <a className="dropdown-item">
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </a> */}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

export default React.memo(TemplatesSub);
