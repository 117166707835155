import { useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
// import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  //   const { isAuthenticated } = useAuth();
  const token = localStorage.getItem("access_token") ? true : false;
  if (!token) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
}
