import React, { useState, createContext } from "react";

export const SizeContext = createContext();

function SizeContextProvider(props) {
  const initialValues = {
    innerWidth: getWindowSize().innerWidth,
    innerHeight: getWindowSize().innerHeight,
  };
  const [size, setSize] = useState(initialValues);

  return (
    <SizeContext.Provider value={[size, setSize]}>
      {props.children}
    </SizeContext.Provider>
  );
}

export function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default SizeContextProvider;
